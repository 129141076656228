import { IClubStoreState } from './clubs';
import { PaymentSystem } from '../store';

export class Strings {
    public static AvailableForInvitationTitle: string = "Available for invitation";
    public static AvailableForInvitationExplanation: string = "Allow other players to invite you to join sessions";
    public static CheckInForVideoReminder: string = "Don't forget to check in at the front desk to start your recording.";
    public static VideoAnalysisNote: string = 'A coach will review the footage and schedule a time with you to provide feedback on your game.'
    public static PaidByOwnerNote: string = "You will pay the full session price. Other players won't be charged";
    public static SharedPriceNote: string = "This price will be reduced when your invitation is accepted";
    public static GroupPaidByOwnerNote: string = "The invited players will not share the cost with you. You will keep paying for the entire session";
    public static VideoSharedEmailSubject: string = "A video has been shared with you";
    public static InvitationEmailSubject: string = "You have been invited to a squash session";
    public static PaidByOwnerNoteForJoining: string = "Session is paid for by host. You will not be charged";
    public static SharedPriceNoteForJoining: string = "Payment will be shared between players";
    public static WaiverDialogTitle: string = "Release Waiver and Grant of Rights";
    public static NoInfo: string = "No Info";
    public static FreePrice: string = 'Free';
    public static Enabled: string = 'Enabled';
    public static Disabled: string = 'Disabled';
    public static CourtPrefix: string = 'Court';
    public static MemberTier: string = 'Member Tier';
    public static MakeGroupAdmin: string = 'Make Group Admin';
    public static RemoveAdminStatus: string = 'Remove Admin Status';
    public static EmptyItem: string = '—';
    public static Yes: string = 'Yes';
    public static No: string = 'No';
    public static EmailNotConfirmed: string = "The email address has not been confirmed";
    public static Cancel: string = 'Cancel';
    public static Administrator: string = 'Administrator';
    public static Closed: string = 'Closed';
    public static Active: string = 'Active';
    public static PasswordPlaceholder: string = '*****';
    public static Other: string = 'Other';
    public static Video: string = 'Video';
    public static UnathorizedAccessTitle: string = 'Please sign in to continue';
    public static SessionPrice: string = 'Session Price';
    public static ServicesPrice: string = 'Additional Services Price';
    public static VideoAnalysis = 'Video Analysis';
    public static SimpleSignUp = 'You already have an account on the Advantage Booking platform. Please enter your password to continue sign-up process.';
    public static UnPublishConfirmationMessage = 'This action does not remove subscribers from the offer. It hides the offer for new purchases.';
    public static SubscriptionCancellation = 'This subscription will no longer be available to new subscribers. Existing subscribers will lose access to this subscription only after the end of their billing cycle, after which the subscription will be archived.';
    public static SubscriptionArchivation = 'The subscription will be sent to archive.';
    public static ChildProtectionCheckbox: string = "Minor form signed by parent / guardian";
    public static MinorsFormNotSigned: string = "This minor account is unverified by a parent / guardian";
    public static AccountNullBirthday: string = "This account was created by an admin and has no birthday";

    public static BookingNote(club: IClubStoreState): string {
        const minimalCancellationPeriod = club.minimalCancellationPeriod;
        const isFeeInPercent = club.isLateCancellationFeeInPercent;
        const lateCancellationFee =
        isFeeInPercent
        ? club.lateCancellationFee === 100
            ? "Full price"
            : club.lateCancellationFee + '% of the price'
        : club.lateCancellationFee + '$';

        return `The Booking can be amended up to ${minimalCancellationPeriod} hours before its start. ${lateCancellationFee} will be charged for any cancellations within ${minimalCancellationPeriod} hours of the Session. If you have invited other player/s the amount you will be charged will be reduced when your invitation is accepted.`;
    }

    public static getCheckSpamMailMessage(club: IClubStoreState, formattedPhone: string, isUnderageUser?: boolean): string {  
        const baseMessage = "Please check your inbox for a confirmation email and click the link to activate your account. Don’t forget to check your Spam folder. The link expires in 24 hours, so activate your account today and get ready to hit the courts!<br>";
        const underageUserAlert = isUnderageUser ? `<br>To book sessions, make purchases, or accept invitations for anyone under 18, we need to get consent first.<br><br>It’s easy! Simply contact us and once we receive your consent, you’ll be all set to hit the courts!<br><br>Call us: ${formattedPhone} <br>Email us: ${club.generalContactEmail}` : '';
        return `${baseMessage}${isUnderageUser ? `<br>**For parents or guardians of minors:** ${underageUserAlert}` : ''}`;
    }

    public static getKidsProtectionPolicyMessage(club: IClubStoreState, formattedPhone: string): string {
        if (!club) return null;
        return `To help you book sessions, make purchases, or accept invitations for someone under 18, we need parental or legal guardian consent first.<br><br>It’s simple! Just have a parent or guardian get in touch.  Once we have the consent, we'll be ready to get you on the courts!<br><br>Call us: ${formattedPhone} <br>Email us: ${club.generalContactEmail}`;
    }

    public static getReceiveNewsTitle(club: IClubStoreState) : string {
        if(!club) return null;
        return `Receive ${club.title} News`;
    }

    public static getCancellationNote(minimumNotificationPeriod: number): string {
        return `This cancellation will affect subscribers after ${minimumNotificationPeriod} days and notification has been sent to the subscribers.`;
    }

    public static getSubscriptionUpdateNote(minimumNotificationPeriod: number): string {
        return `Subscribers will have ${minimumNotificationPeriod} days to opt-out and a notification will be sent if they cancel their subscription.`;
    }

    public static PaymentSystems = new Map<PaymentSystem, string>([
        [PaymentSystem.AuthorizeNet, 'Authorize.Net'], 
        [PaymentSystem.Stripe, 'Stripe']
    ]);
}

export class ValidationMessages {
    public static Default: string = 'Please check your information and try again';
    public static AccountInfo: string = 'Please fill in all marked fields to continue';
    public static CoachAssignationError: string = 'Coach is not available for selected courts';
    public static PackageCoachTierValidationError: string = 'Coach Tier field is required, when any Clinic or Lesson credit is added';
}

export default Strings;
