import '../dialogs.scss';
import './authDialog.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import moment from 'moment';

import { Modal, Field, FormError } from '../../../components';
import * as SrvM from '../../../services';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import DatePicker from '../../../components/datepicker';
import { DialogHashParam } from '../../../appSettings';
import Strings from "../../../models/store/strings";

export interface AuthDialogProps {
    isShow: boolean;
    tab: string;
    returnUrl: string;
    isAuth: boolean;

    match: any;

    club: StM.IClubStoreState;
    isWaiverAccepted: boolean;
    isTermsAndConditionsAccepted: boolean;
    user: StM.IUserStoreState;

    closeAuthDialog: () => void;
    openTab: (tab: string, returnUrl: string) => Promise<any>;
    getIsUniqueEmail: (email: string) => Promise<boolean>;
    getIsSharedEmail: (email: string) => Promise<boolean>;
    login: (userName: string, password: string) => Promise<any>;
    resendValidationEmail: (userName: string) => Promise<any>;
    signUp: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string,
        newsletterSubscribe: boolean, dateOfBirth: moment.Moment, gender: string, phone: string, isAvailableForInvitation: boolean) => Promise<any>;
    getSharedUser: (email: string, password: string) => Promise<any>;
    getCurrentUser: () => Promise<any>;
    getActives: () => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    showAlert: (msgKey: string, msgType?: string, msg?: string) => Promise<any>;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    openRecoveryPasswordDialog: () => Promise<any>;
    openWaiverDialog: () => Promise<any>;
    openDemographicInfoDialog: () => Promise<any>;
    openBillingInfoDialog: (returnUrl: string) => Promise<any>;
    logout: () => Promise<any>;
    go: (url: string) => any;
    setProfileCompletionConfirmation: (value: boolean) => void;
    closeAlert: () => void;
    reloadPage: () => void;
}

export interface AuthDialogState {
    errors: any;
    login: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    repeatEmail: string;
    newsletterSubscribe: boolean;
    isNeedValidateEmail: boolean;
    dateOfBirth: moment.Moment;
    gender: string;
    phone: string;
    isAvailableForInvitation: boolean;
    isEmailChecked: boolean;
    isEmailShared: boolean;
    useSimplifiedSignUp: boolean;
}

class AuthDialog extends React.Component<AuthDialogProps, AuthDialogState> {
    private utils = new SrvM.Utils();
    private validator = new SrvM.ValidationService();
    private formValidator = new SrvM.FormValidationService();

    private isWaitWaiver: boolean;
    private isWaiverAccepted: boolean;
    private customWindow: any;

    constructor(props: AuthDialogProps) {
        super(props);

        this.isWaitWaiver = false;
        this.isWaiverAccepted = false;

        this.state = {
            errors: null,
            login: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            repeatEmail: '',
            newsletterSubscribe: true,
            isNeedValidateEmail: false,
            dateOfBirth: null,
            gender: null,
            phone: null,
            isAvailableForInvitation: true,
            isEmailChecked: false,
            isEmailShared: false,
            useSimplifiedSignUp: false
        };
    }

    componentDidMount() {
        this.customWindow = window;
    }

    componentDidUpdate(prevProps: AuthDialogProps) {
        const isShowChanged = !prevProps.isShow && this.props.isShow;
        const isTabChanged = this.props.isShow && (prevProps.tab !== this.props.tab);
        const isTermsAndConditionsChanged = !prevProps.isTermsAndConditionsAccepted && this.props.isTermsAndConditionsAccepted;
        const isAuthChanged = !prevProps.isAuth && this.props.isAuth;

        if (isShowChanged || isTabChanged) {
            this.isWaitWaiver = false;
            this.isWaiverAccepted = false;

            this.setState({
                errors: null,
                login: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
                email: '',
                repeatEmail: '',
                newsletterSubscribe: true,
                isNeedValidateEmail: false,
                dateOfBirth: null,
                gender: null,
                phone: null,
                isAvailableForInvitation: true,
                isEmailChecked: false,
                isEmailShared: false,
                useSimplifiedSignUp: false
            });
        }

        if (this.isWaitWaiver && this.props.isWaiverAccepted && isTermsAndConditionsChanged) {
            this.isWaitWaiver = false;
            this.isWaiverAccepted = true;
            if (this.props.tab === StM.AuthDialogTabs.SignIn) {
                this.deferredLogin();
            } else if (this.props.tab === StM.AuthDialogTabs.SignUp) {
                this.deferredSignUp();
            }
        }

        if (this.isWaitWaiver && prevProps.isShow && !this.props.isShow) {
            this.props.logout();
        }

        if (isAuthChanged || (isShowChanged && this.props.isAuth)) {
            this.props.closeAuthDialog();
        }
    }

    render() {
        if (this.props.isShow && !this.props.isAuth) {
            const isSignIn = this.props.tab.toUpperCase() === StM.AuthDialogTabs.SignIn.toUpperCase();
            const currentTabContent = isSignIn ? this.getSignInTab() : this.getSignUpTab();
            const signInTabClasses = ClassNames({ 'active': isSignIn });
            const signUpTabClasses = ClassNames({ 'active': !isSignIn });
            return (
                <Modal classes="sign-in" closeHandler={this.props.closeAuthDialog} dialogName={StM.DialogNames.Auth}>
                    <div className="modal-dialog">
                        <div className="modal-dialog-header">
                            <span className="close-dialog" onClick={(e) => { this.onCloseClick(e) }}></span>
                            <div className="tabs-wrapper">
                                <ul className="tabs-navigation">
                                    <li className={signInTabClasses} onClick={(e) => { this.onTabClick(e, StM.AuthDialogTabs.SignIn) }}><a>Log In</a></li>
                                    {!this.customWindow.ISADMIN && (<li className={signUpTabClasses} onClick={(e) => { this.onTabClick(e, StM.AuthDialogTabs.SignUp) }}><a>Sign Up</a></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {currentTabContent}
                </Modal>
            );
        }
        return null;
    }

    getSignInTab() {
        const bookUrl = encodeURIComponent(`${DialogHashParam}=${StM.DialogNames.Book}`).toLowerCase();
        const isBookUrl = (this.props.returnUrl == bookUrl);

        return (
            <div className="modal-dialog-body sign-in-body">
                {
                    isBookUrl ?
                        <div className="warning-no-login-user">
                            <div>Please sign in to book the selected sessions.</div>
                            <div>We'll need your credit card information to complete the check-out.</div>
                        </div> : ""
                }

                <form className="form-wrapper" onSubmit={(e) => this.onLogInClick(e)} autoComplete="on">
                    <div className="inputs-wrapper">
                        <Field name="login"
                            type="text"
                            className="email-login"
                            disabled={this.state.isNeedValidateEmail}
                            placeholder="Email"
                            id="login-input"
                            autoComplete="on"
                            error={this.state.errors && this.state.errors.login}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="password"
                            type="password"
                            className="password-login"
                            placeholder="Password"
                            disabled={this.state.isNeedValidateEmail}
                            id="password-input"
                            autoComplete="on"
                            error={this.state.errors && this.state.errors.password}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                    </div>
                    <FormError errors={this.state.errors} />
                    <div className="btns-wrapper">
                        <div className="log-in-wrapper">
                            {!this.state.isNeedValidateEmail && (<button className="btn btn-block btn-red" type="submit">LOG IN</button>)}
                            {!this.customWindow.ISADMIN && this.state.isNeedValidateEmail && (<button className="btn btn-block btn-red" type="button" onClick={(e) => { this.onResendEmailClick(e) }}>RESEND EMAIL</button>)}
                        </div>
                    </div>
                </form>
                <div className="divider-line"></div>
                <div className="btn-forgot-pass-wrapper">
                    <span className="btn-forgot-pass" onClick={(e) => { this.onForgotClick(e) }}>Forgot password?</span>
                </div>
            </div>
        )
    }

    getSignUpTab() {
        return (
            <div className="modal-dialog-body sign-up-body">
                <form className="form-wrapper" autoComplete="off">
                    <div className="inputs-wrapper">
                        <Field name="email"
                               type="text"
                               label="Email"
                               error={this.state.errors && this.state.errors.email}
                               maxLength={128}
                               autoComplete="off"
                               disabled={this.state.isEmailChecked}
                               value={this.state.email}
                               onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />

                        {this.state.isEmailChecked && this.state.isEmailShared && this.renderSimplifiedSignUp()}
                        {this.state.isEmailChecked && !this.state.isEmailShared && this.renderStandartSignUp()}

                    </div>
                    <FormError errors={this.state.errors} />
                    <div className="btns-wrapper">
                        <button className="btn btn-block btn-red" onClick={(e) => this.onSignUpClick(e)}>NEXT</button>
                    </div>
                </form>
            </div>
        )
    }

    private renderSimplifiedSignUp()
    {
        return (
            <div>
                <Field name="password"
                       type="password"
                       label="Password"
                       error={this.state.errors && this.state.errors.password}
                       maxLength={128}
                       autoComplete="off"
                       value={this.state.password}
                       onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
                
                <div className="info-message-wrapper " >
                    <span className="info-message-title">{Strings.SimpleSignUp}</span>
                </div>
            </div>
        )
    }

    private renderStandartSignUp()
    {
        return (
            <div>
                {!this.state.useSimplifiedSignUp && <Field name="repeatEmail"
                                                           type="text"
                                                           label="Confirm Email"
                                                           error={this.state.errors && this.state.errors.repeatEmail}
                                                           maxLength={128}
                                                           autoComplete="off"
                                                           disabled={this.state.useSimplifiedSignUp}
                                                           value={this.state.repeatEmail}
                                                           onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />}
                <Field name="firstName"
                       type="text"
                       label="First Name"
                       error={this.state.errors && this.state.errors.firstName}
                       maxLength={32}
                       disabled={this.state.useSimplifiedSignUp}
                       value={this.state.firstName}
                       onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
                <Field name="lastName"
                       type="text"
                       label="Last Name"
                       error={this.state.errors && this.state.errors.lastName}
                       maxLength={32}
                       disabled={this.state.useSimplifiedSignUp}
                       value={this.state.lastName}
                       onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
                {!this.state.useSimplifiedSignUp && <Field name="password"
                                                          type="password"
                                                          label="Password"
                                                          error={this.state.errors && this.state.errors.password}
                                                          maxLength={128}
                                                          autoComplete="off"
                                                          value={this.state.password}
                                                          onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />}
                {!this.state.useSimplifiedSignUp && <Field name="confirmPassword"
                                                          type="password"
                                                          label="Confirm Password"
                                                          error={this.state.errors && this.state.errors.confirmPassword}
                                                          maxLength={128}
                                                          autoComplete="off"
                                                          disabled={!this.state.password}
                                                          value={this.state.confirmPassword}
                                                          onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />}
                <Field name="dateOfBirth" label="Date of Birth" error={this.state.errors && this.state.errors.dateOfBirth}>
                    <DatePicker classes="input-item date-of-birth"
                                date={this.state.dateOfBirth}
                                disabled={this.state.useSimplifiedSignUp}
                                onChange={(date) => this.handleFieldChange('dateOfBirth', this.utils.toDateOfBirth(date))}
                    />
                </Field>
                <div className="input-item checkbox-available">
                    <div className="checkbox-wrapper">
                        <label className="available-invite">
                            <input type="checkbox" hidden checked={this.state.isAvailableForInvitation} onChange={(e: any) => this.handleFieldChange('isAvailableForInvitation', e.target.checked)} />
                            <span>{Strings.AvailableForInvitationTitle}</span>
                        </label>
                        <div className="news-description">{Strings.AvailableForInvitationExplanation}</div>
                    </div>
                </div>
                <div className="input-item squash-news-item">
                    <label className="squash-news">
                        <input type="checkbox" hidden checked={this.state.newsletterSubscribe} onChange={(e: any) => this.handleFieldChange('newsletterSubscribe', e.target.checked)} />
                        <span>{Strings.getReceiveNewsTitle(this.props.club)}</span>
                    </label>
                </div>
            </div>
        )
    }

    private onCloseClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeAuthDialog();
    }

    private onTabClick(e: any, tab: string) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.props.tab.toUpperCase() == tab.toUpperCase()) return false;
        this.setState({ ...this.state, errors: null });
        this.props.openTab(tab, this.props.returnUrl);
    }

    private loginValidate() {
        return {
            ...this.validator.validateRequired(this.state.login, 'login', 'Login'),
            ...this.validator.validatePassword(this.state.password, 'password'),
        };
    }

    private onLogInClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        const errors = this.loginValidate();
        if (this.validator.hasErrors(errors)) {
            this.setState({ ...this.state, errors });
            return false;
        }

        this.props.showSpinner();
        this.props.login(this.state.login, this.state.password).then(() => {
            return this.props.getCurrentUser();
        }).then((user: StM.IUserStoreState) => {
            if (user.isAcceptedWaiver || this.isWaiverAccepted) {
                return this.deferredLogin();
            }
            if (!this.isWaiverAccepted) {
                this.isWaitWaiver = true;
                this.props.openWaiverDialog();
            }
        }).then(() => {
            this.props.hideSpinner();
        }).catch((errorData) => {
            this.props.hideSpinner();
            this.props.closeAlert();
            if (errorData && errorData.response) {
                if (errorData.response.data && errorData.response.data.error === 'validate_email_error') {
                    this.setState({ ...this.state, isNeedValidateEmail: true });
                }
                if (errorData.response.status === 401)
                    this.setState({ ...this.state, errors: { server: errorData.response.data?.detail ?? 'The email or password is incorrect.' } });
                else
                    this.setState({ ...this.state, errors: { server: errorData.response.data.error_description } });
            } else {
                this.setState({ ...this.state, errors: { server: errorData ? errorData.toString() : 'Some error occurred.' } });
            }
        });
    }

    private deferredLogin(skipUserRefresh: boolean = false) {
        this.props.showSpinner();
        var action = skipUserRefresh ? this.props.getCurrentUser() : Promise.resolve(true);
        return action.then(() => {
                this.props.closeAuthDialog();
                if (this.props.returnUrl) {
                    window.location.hash = decodeURIComponent(this.props.returnUrl);
                    return;
                }
                else if (this.customWindow.ISADMIN) {
                    this.props.go(new SrvM.RouteService().getScheduleTodayRoute(this.props.club, this.props.user));
                }
                //return this.props.updateCurrentPageSessions(this.props.match.params);
            }).then(() => {
                if (this.utils.getIsOpenDemographicInfoDialog(this.props.user)) {
                    this.props.openDemographicInfoDialog();
                }
                else if (!this.props.user.paymentProfileId ||
                    this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.Suspended ||
                    this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.NotInitialized) {
                    this.props.openBillingInfoDialog(null);
                }
                return this.props.hideSpinner();
            })
            .catch((errorData) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (errorData && errorData.response && errorData.response.data) {
                    if (errorData.response.data.error && errorData.response.data.error == 'validate_email_error') {
                        this.setState({ ...this.state, isNeedValidateEmail: true });
                    }
                    this.setState({ ...this.state, errors: { server: errorData.response.data.error_description } });
                } else {
                    this.setState({ ...this.state, errors: { server: errorData ? errorData.toString() : 'Some error occured.' } });
                }
            });
    }

    private onSignUpClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        class ThisNameIsAlreadyTakenError extends Error {
            constructor(message?: string) {
                super(message);
                this.message = message;
            }
        };

        this.props.showSpinner();

        if (this.state.isEmailChecked) {
            if (this.state.isEmailShared) {
                this.props.getSharedUser(this.state.email, this.state.password)
                    .then((user: IUserRegistrationDto) => {
                        this.setState({ ...this.state, isEmailChecked: true, isEmailShared: false,
                            useSimplifiedSignUp: true,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            repeatEmail: user.email,
                            dateOfBirth: this.utils.toDateOfBirth(moment(user.dateOfBirth)),
                            gender: user.gender,
                            phone: user.phone,
                            confirmPassword: this.state.password,
                            isAvailableForInvitation: user.isAvailableForInvitation,
                            newsletterSubscribe: user.newsletterSubscribe
                        });

                        this.props.hideSpinner();
                    })
                    .catch(error => {
                        this.props.hideSpinner();
                        this.props.closeAlert();
                        let errorMessage = error.response.data;
                        this.setState({ ...this.state, errors: { server: errorMessage } });
                    });

                return;
            }

            const errors = this.formValidator.validateSignUpForm(this.state, this.props.club);
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                this.props.hideSpinner();
                return false;
            }

            if (this.isWaiverAccepted) {
                this.deferredSignUp();
            } else {
                this.isWaitWaiver = true;
                this.props.openWaiverDialog();
                this.props.hideSpinner();
            }
        }
        else {
            const errors = this.formValidator.validateSimplifiedSignUpForm(this.state, this.props.club);
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                this.props.hideSpinner();
                return false;
            }

            this.props.getIsUniqueEmail(this.state.email)
                .then((isUniqueEmail: boolean) => {
                    if (!isUniqueEmail) {
                        throw new ThisNameIsAlreadyTakenError('This email is already taken');
                    }})
                .then(() => {
                    this.props.getIsSharedEmail(this.state.email)
                        .then((isSharedEmail: boolean) => {
                            if (isSharedEmail) {
                                this.setState({ ...this.state, isEmailChecked: true, isEmailShared: true });
                            }
                            else {
                                this.setState({ ...this.state, isEmailChecked: true, isEmailShared: false });
                            }
                            this.props.hideSpinner();
                        })
                })
                .catch(error => {
                    this.props.hideSpinner();
                    this.props.closeAlert();
                    let errorMessage = '';
                    if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                        errorMessage = error.response.data.exceptionMessage;
                    } else if(error && error.message) {
                        errorMessage = error.message;
                    } else {
                        errorMessage = error ? error.toString() : 'Some error occured.';
                    }
                    this.setState({ ...this.state, errors: { server: errorMessage }, isEmailChecked: false });
                });
        }

    }

    private deferredSignUp() {
        this.props.showSpinner();
        const isUnderageUser = this.utils.checkIsBirthdayUnderage(this.state.dateOfBirth, this.props.club);
        this.props.signUp(this.state.firstName, this.state.lastName, this.state.email, this.state.password, this.state.confirmPassword,
            this.state.newsletterSubscribe, this.state.dateOfBirth, this.state.gender, this.state.phone, this.state.isAvailableForInvitation)
            .then(() => {
                return Promise.all([this.props.getCurrentUser(), this.props.getActives()]);
            })
            .then(() => {
                this.props.hideSpinner()
                if (this.props.returnUrl) {
                    window.location.hash = decodeURIComponent(this.props.returnUrl);
                    this.props.reloadPage();
                }
                this.props.setProfileCompletionConfirmation(true);
                const formattedPhone = this.utils.formatPhone(this.props.club.phone)
                this.props.showAlert(
                    `Welcome to ${this.props.club.title}`,
                    StM.MessageTypes.Info, 
                    Strings.getCheckSpamMailMessage(this.props.club, formattedPhone, isUnderageUser)
                );

                this.props.closeAuthDialog();
                return this.props.openDemographicInfoDialog();
            })
            .catch(error => {
                this.props.hideSpinner();
                this.props.closeAlert();
                let errorMessage = '';
                if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                    errorMessage = error.response.data.exceptionMessage;
                } else if (error && error.message) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error ? error.toString() : 'Some error occured.';
                }
                this.setState({...this.state, errors: {server: errorMessage}});
            });
    }

    private handleFieldChange(name: string, value: any) {
        let errors = !!this.state.errors && { ...this.state.errors };
        if (this.validator.hasErrors(errors))
        {
            delete errors[name];
            delete errors['server'];
        }
        this.setState({
            ...this.state,
            [name]: value,
            errors
        });
    }

    private onForgotClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeAuthDialog();

        this.props.openRecoveryPasswordDialog();
    }

    private onResendEmailClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.showSpinner();
        this.props.resendValidationEmail(this.state.login)
            .then(() => {
                this.props.closeAuthDialog();
                this.props.showAlert(StM.MessagesKey.ValidationEmailResending);
                this.props.hideSpinner();
            }).catch((error) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                let errorMessage = '';
                if (error && error.response && error.response.data) {
                    errorMessage = error.response.data;
                } else if (error && error.message) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error ? error.toString() : 'Some error occured.';
                }
                this.setState({ ...this.state, errors: { server: errorMessage } });
            });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        match: state.app.match
        , isShow: state.dialogs.auth.isOpened && !state.dialogs.passwordChanging.isOpened
        , tab: state.dialogs.auth.tab
        , returnUrl: (state.dialogs.auth.returnUrl || '')
        , isAuth: state.app.isAuthorized
        , club: state.club
        , isWaiverAccepted: state.dialogs.waiver.isAccepted
        , isTermsAndConditionsAccepted: state.dialogs.termsAndConditions.isAccepted
        , user: state.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeAuthDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Auth))
        , openRecoveryPasswordDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.PasswordRecovery))
        , openWaiverDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Waiver))
        , openDemographicInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.DemographicInformation))
        , openBillingInfoDialog: (returnUrl: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo, { returnUrl: returnUrl }))
        , openTab: (tab: string, returnUrl: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: tab, returnUrl: returnUrl }))
        , login: (userName: string, password: string) => dispatch(ActM.AccountActions.login(userName, password))
        , resendValidationEmail: (userName: string) => dispatch(ActM.AccountActions.resendValidationEmail(userName))
        , getIsUniqueEmail: (email: string) => dispatch(ActM.AccountActions.getIsUniqueEmail(email))
        , getIsSharedEmail: (email: string) => dispatch(ActM.AccountActions.getIsSharedEmail(email))
        , signUp: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string, newsletterSubscribe: boolean, dateOfBirth: moment.Moment, gender: string, phone: string, isAvailableForInvitation: boolean) =>
            dispatch(ActM.AccountActions.signUp(firstName, lastName, email, password, confirmPassword, newsletterSubscribe, dateOfBirth, gender, phone, isAvailableForInvitation))
        , getSharedUser: (email: string, password: string) => dispatch(ActM.AccountActions.getSharedUser(email, password))
        , getCurrentUser: () => dispatch(ActM.UserActions.getCurrent())
        , getActives: () => dispatch(ActM.ActiveUsersActions.get(true))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params))
        , showAlert: (msgKey: string, msgType: string = StM.MessageTypes.Success, msg?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: msgType, message: msg }))
        , logout: () => dispatch(ActM.AccountActions.logout())
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , setProfileCompletionConfirmation: (value: boolean) => dispatch(ActM.UserActions.setProfileCompletionConfirmation(value))
        , closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
        , reloadPage: () => dispatch(ActM.BaseActions.reloadPage())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthDialog);
